import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import {createPinia} from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueMatomo from "vue-matomo";

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/apitour.variables.css';

/* FA icons */
import "./theme/fa/fontawesome.scss";
import "./theme/fa/light.scss";
import "./theme/fa/regular.scss";
import "./theme/fa/solid.scss";

import './theme/global.scss';
import './theme/header.scss';
import './theme/results.scss';

import './registerServiceWorker';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

const app = createApp(App).use(IonicVue, {mode: 'ios', rippleEffect: false})
    .use(pinia)
    .use(router)
    .use(VueMatomo, {
      host: `https://analytics.${process.env.VUE_APP_ENVIRONMENT}.apitour.fr`,
      siteId: process.env.VUE_APP_MATOMO_SITE_ID,
      router: router
    });
  
router.isReady().then(() => {
  app.mount('#app');
});
