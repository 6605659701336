import {createRouter, createWebHistory} from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue'
import {tripGuard} from "@/router/trip-guard";

const routes = [
    {
        path: '/',
        redirect: '/app/explorer'
    },
    {
        path: '/app/',
        component: TabsPage,
        beforeEnter: tripGuard,
        children: [
            {
                path: '',
                redirect: '/app/explorer'
            },
            {
                path: 'explorer',
                component: () => import('@/views/ExplorePage.vue')
            },
            {
                path: 'conseils',
                component: () => import('@/views/SelectionPage.vue')
            },
            {
                path: 'sejour',
                component: () => import('@/views/TripPage.vue')
            },
            {
                path: 'reglages',
                component: () => import('@/views/ConfigPage.vue')
            }
        ]
    },
    {
        path: "/connexion",
        name: "tripSearch",
        component: () => import("@/views/TripSearch.vue")
    },
    {
        path: "/rejoindre-sejour",
        name: "tripMatch",
        component: () => import("@/views/TripMatch.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router;
