import {defineStore} from "pinia";
import {postData} from "@/stores/http";

export const useUserStore = defineStore("user", {
    state: () => {
        return {
            currentTripCode: '',
            currentUser: '',
            currentSelectionCounters: {},
            currentTripCounters: {},
            activeTrip: {},
            usersTrips: {}
        };
    },
    getters: {
        currentUserTrip: (state: any) => state.currentUser ? state.usersTrips[state.currentUser] : {},
    },
    actions: {
        setUserContext() {
            if (!this.usersTrips[this.currentUser]) {
                this.usersTrips[this.currentUser] = this.defaultTrip();
            }
            window['_paq'].push(['setUserId', this.currentUser]);
            this.loadUserTrip();
        },
        setTripContext(tripCode: string, visitorRef: string, tripData: any) {
            this.currentTripCode = tripCode;
            this.currentUser = `${tripCode}-${visitorRef}`;
            this.activeTrip = {...tripData, ...{trip_results: {...this.defaultTrip(), ...tripData.trip_results}}};
            this.updateSelectionCounters();
        },
        toggleTripItem(section: string, item: any) {
            console.debug('store toggleTripItem: ' + JSON.stringify({section, apidaeId: item.apidae_id}));
            if (this.currentUser && this.usersTrips[this.currentUser]) {
                const userTrip: any = {};
                for (const s in this.usersTrips[this.currentUser]) {
                    userTrip[s] = this.usersTrips[this.currentUser][s].map((itm: any) => itm.apidae_id);
                }
                const itemIndex: number = userTrip[section].indexOf(item.apidae_id);
                if (itemIndex > -1) {
                    userTrip[section].splice(itemIndex, 1);
                    window['_paq'].push(['trackEvent', 'Retrait sélection', item.title]);
                } else {
                    userTrip[section].push(item.apidae_id);
                    window['_paq'].push(['trackEvent', 'Ajout sélection', item.title]);
                }
                return postData(`${process.env.VUE_APP_BACKEND_URL}/requests/custom_trip/${this.currentUser}.json`,
                    {custom_trip: {[this.currentUser]: userTrip}}).then(({success, custom_trip, message}: any) => {
                        if (success) {
                            this.usersTrips[this.currentUser] = {...custom_trip};
                            this.updateTripCounters();
                        } else {
                            console.error("Update custom trip error: " + message);
                        }
                });
            }
        },
        loadUserTrip() {
            return fetch(`${process.env.VUE_APP_BACKEND_URL}/requests/custom_trip/${this.currentUser}.json`)
                .then(resp => resp.json()).then(customTrip => {
                this.usersTrips[this.currentUser] = {...customTrip};
                this.updateTripCounters();
            }).catch(() => {
                console.error("could not retrieve trip data for user " + this.currentUser);
            });
        },
        logOutUser() {
            this.currentTripCode = '';
            this.currentUser = '';
            this.activeTrip = {};
            window['_paq'].push(['setUserId', '']);
        },
        updateTripCounters() {
            const counters = {'all': 0};
            for (const s in this.usersTrips[this.currentUser]) {
                counters[s] = (this.usersTrips[this.currentUser][s] || []).length;
                counters['all'] += counters[s];
            }
            this.currentTripCounters = {...counters};
        },
        updateSelectionCounters() {
            const counters = {'all': 0};
            for (const s in this.activeTrip['trip_results']) {
                counters[s] = (this.activeTrip['trip_results'][s] || []).length;
                counters['all'] += counters[s];
            }
            this.currentSelectionCounters = {...counters};
        },
        defaultTrip() {
            return {"incontournables": [], "activites-et-loisirs": [], "restaurants": [], "fete-evenement": [],
                "hebergement": [], "unknown": []};
        }
    },
    persist: true
});
