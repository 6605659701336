import router from "@/router";
import { useUserStore } from '@/stores/user';

export async function tripGuard() {
    const store = useUserStore();

    if (!store.currentUser) {
        router.push('/connexion');
    }
    // const token = localStorage.getItem("token");
    //
    //
    // if (token) {
    //
    // } else {
    //     router.push("/connexion");
    // }
}
